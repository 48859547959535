/* eslint-disable no-unused-vars */
import React, { useMemo } from "react";
import { Table } from "antd";
import { ColumnProps } from "antd/lib/table";
import { useTranslation } from "react-i18next";

import { EditableCell } from "./EditableCell";
import { ITherapyVariable } from "../../../../services";
import { primaryColor } from "../../../../shared";
import { IFormItemValidator, useValidateParticipantTherapyConfig } from ".";

interface IRowItemType extends IFormItemValidator {
  key: string;
  displayName: string;
  description: string;
  variableType: string;
  mandatory: boolean;
  validatorProps: any;
}

export interface ITherapyVariableValue {
  therapyVariableId: string;
  therapyVariableValue: any;
}

export interface IParticipantTableProps {
  participantId: string;
  editing?: boolean;
  therapyVariables: ITherapyVariable[];
  therapyVariablesValues: ITherapyVariableValue[];
}

export function TherapyElementsParticipantConfig({
  participantId,
  editing = false,
  therapyVariables,
  therapyVariablesValues,
}: IParticipantTableProps) {
  const { t } = useTranslation();
  const { validateVariableValue } = useValidateParticipantTherapyConfig(participantId);

  const initData: IRowItemType[] = useMemo(
    () =>
      therapyVariables
        .filter((variable) => variable.configurationLevel.therapistEdit)
        .sort((a, b) => b.order - a.order)
        .map((filteredVariable) => {
          const value = therapyVariablesValues.find(
            (therapyVariableValue) =>
              therapyVariableValue.therapyVariableId === filteredVariable.id,
          );
          if (value === undefined) {
            throw Error(`No Value for variable with id ${filteredVariable.id}`);
          } else {
            return {
              key: filteredVariable.id,
              description: filteredVariable.description,
              displayName: filteredVariable.displayName,
              variableType: filteredVariable.type,
              validatorProps: filteredVariable.validatorProps,
              mandatory: filteredVariable.mandatory,
              value: value.therapyVariableValue,
              validate: validateVariableValue,
            };
          }
        }),
    [therapyVariables, therapyVariablesValues],
  );

  const columns: ColumnProps<IRowItemType>[] = [
    {
      title: t("study.therapyConfig.variableName"),
      dataIndex: "displayName",
      width: "20%",
    },
    {
      title: t("study.therapyConfig.variableDescription"),
      dataIndex: "description",
      width: "40%",
    },
    {
      title: t("study.therapyConfig.participantValue"),
      width: "40%",
      onCell: (record: IRowItemType) => ({
        title: editing ? undefined : t("study.therapyconfig.valueOnHover"),
        dataIndex: record.key,
        editableCell: true,
        variableType: record.variableType,
        mandatory: record.mandatory,
        validatorProps: record.validatorProps,
        validate: record.validate,
        editing,
      }),
    },
  ];

  return (
    <Table
      style={{
        width: 1100,
        margin: "auto",
        borderStyle: editing ? "solid" : "none",
        borderWidth: 1,
        borderRadius: 4,
        borderColor: primaryColor,
        boxShadow: editing ? `0px 0px 20px 2px #87e8de` : "",
      }}
      components={{
        body: {
          cell: EditableCell,
        },
      }}
      pagination={false}
      size="small"
      bordered
      dataSource={initData}
      columns={columns}
    />
  );
}
