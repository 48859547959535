import { Typography } from "antd";
import React, { ReactNode } from "react";
import {
  backgroundLoginStyle,
  loginDivStyle,
  loginTitleStyle,
  loginWrapperStyle,
} from "../../shared";

const { Title } = Typography;

export const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};
export const tailLayout = {
  wrapperCol: { offset: 0, span: 24 },
};

interface ILoginContainerProps {
  title: String;
  children: ReactNode;
}

function LoginContainer(props: ILoginContainerProps) {
  const { title, children } = props;

  return (
    <div style={backgroundLoginStyle}>
      <Title style={loginTitleStyle}>TherapyDesigner</Title>
      <div style={loginWrapperStyle}>
        <div style={loginDivStyle}>
          <Title
            data-id="USER_INFORMATION_HEADLINE"
            level={4}
            style={{
              textAlign: "center",
              fontWeight: 300,
              fontSize: 24,
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            {title}
          </Title>
          {children}
        </div>
      </div>
    </div>
  );
}

export default LoginContainer;
