/* eslint-disable react/require-default-props */

import React, { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";
import { Button, Result } from "antd";
import { NavHashLink } from "react-router-hash-link";

import {
  GroupConfigsToUpdate,
  GroupVariableAssignment,
  ParticipantsGroupFromQuery,
  ITherapyElement,
  useUpdateGroupVariableConfig,
} from "../../../../services/graphql";

import { DummyAnchor, getTherapyConfigHashLink, Paths, useUserId } from "../../../../shared";

import {
  TherapyElementContainer,
  TherapyElementsGroupConfig,
} from "../../components/therapyConfigTable";
import TherapyConfigForm from "../../components/therapyConfigTable/TherapyConfigForm";

import {
  sortTherapyElementsByTitle,
  addObjectField,
  filterForEditedTherapyValues,
} from "../../../../utils";

const generateTableOfContent = (therapyElements: ITherapyElement[]) => {
  const therapyConfigStructure = {};
  therapyElements?.forEach((element) =>
    addObjectField({
      object: therapyConfigStructure,
      path: element.title,
      delimiter: ":",
      value: element.id,
    }),
  );
  return (
    <ul
      style={{
        marginTop: 16,
      }}
    >
      {generateContentFromObjectStructure(therapyConfigStructure)}
    </ul>
  );
};

const generateContentFromObjectStructure = (object: any) => {
  const content: ReactNode = Object.keys(object).map((key) => {
    if (typeof object[key] === "object" && object[key] !== null) {
      return (
        <>
          <li>
            <NavHashLink
              scroll={(el) => {
                const y = el.getBoundingClientRect().top + window.pageYOffset - 72;
                window.scrollTo({ top: y, behavior: "smooth" });
              }}
              smooth
              to={getTherapyConfigHashLink(key)}
            >
              {key}
            </NavHashLink>
          </li>
          <ul>{generateContentFromObjectStructure(object[key])}</ul>
        </>
      );
    }
    return (
      <li>
        <NavHashLink
          scroll={(el) => {
            const y = el.getBoundingClientRect().top + window.pageYOffset - 72;
            window.scrollTo({ top: y, behavior: "smooth" });
          }}
          smooth
          to={getTherapyConfigHashLink(object[key])}
        >
          {key}
        </NavHashLink>
      </li>
    );
  });
  return content;
};

interface IParticipantsGroupsTherapyConfigContentProps {
  studyId: string;
  hasPermissionToEdit: boolean;
  groups?: ParticipantsGroupFromQuery[];
  elementsWithEditableVariables?: ITherapyElement[];
  groupVariableAssignment?: GroupVariableAssignment[];
}

export function ParticipantsGroupsTherapyConfigContent({
  studyId,
  hasPermissionToEdit,
  groups = [],
  elementsWithEditableVariables = [],
  groupVariableAssignment = [],
}: IParticipantsGroupsTherapyConfigContentProps) {
  const { t } = useTranslation();
  const { userId } = useUserId();
  const [forceRefresh, setForceRefresh] = useState(false);
  const { updateGroupVariableConfig } = useUpdateGroupVariableConfig({
    studyId,
    onError: () => setForceRefresh((prev: boolean) => !prev),
  });
  const studyRoutes = Paths.getStudyPaths(studyId);

  const handleOnFormFinish = (editedValues: any, initialValues: any) => {
    const filteredValues = filterForEditedTherapyValues({ editedValues, initialValues });

    const preparedConfigArray: GroupConfigsToUpdate[] = filteredValues.map(
      ({ key, currentValue }) => ({
        id: key,
        variableValue: currentValue,
        editorId: userId,
      }),
    );
    updateGroupVariableConfig(preparedConfigArray);
  };

  const studyHasGroups = groups.length > 0;

  if (!studyHasGroups) {
    return (
      <Result
        style={{ margin: "auto", width: "75%", background: "white", padding: 40 }}
        title={t("study.participantsGroups.therapyConfig.noGroupResult.title")}
        subTitle={t("study.participantsGroups.therapyConfig.noGroupResult.description")}
        extra={
          <Button type="primary">
            <Link to={studyRoutes.PARTICIPANTS_GROUPS}>{t("study.menu.manageGroups")}</Link>
          </Button>
        }
      />
    );
  }

  if (elementsWithEditableVariables.length === 0) {
    return (
      <Result
        style={{ margin: "auto", width: "75%", background: "white", padding: 40 }}
        title={t("study.participantsGroups.therapyConfig.noVariablesResult.title")}
        subTitle={t("study.participantsGroups.therapyConfig.noVariablesResult.description")}
      />
    );
  }

  const initialValuesArray = groupVariableAssignment.map((assignment) => ({
    key: assignment.id,
    value: assignment.variableValue,
  }));

  const initialValueObject = Object.fromEntries(initialValuesArray.map((e) => [e.key, e.value]));

  return (
    <TherapyConfigForm
      title={t("study.participantsGroups.therapyConfig.title")}
      description={t("study.participantsGroups.therapyConfig.description")}
      additionalHeaderContent={generateTableOfContent(elementsWithEditableVariables)}
      editable={hasPermissionToEdit}
      affixOffsetTop={74}
      initialValues={initialValueObject}
      onFinish={(values) => handleOnFormFinish(values, initialValueObject)}
      forceValueRefresh={forceRefresh}
    >
      {sortTherapyElementsByTitle(elementsWithEditableVariables).map((item) => {
        if (typeof item === "string") {
          return <DummyAnchor key={item} id={item} />;
        }
        if (item.therapyVariables.length > 0) {
          return (
            <TherapyElementContainer
              id={item.id}
              key={item.id}
              title={item.title}
              description={item.description}
            >
              <TherapyElementsGroupConfig
                groups={groups}
                therapyVariables={item.therapyVariables}
                variablesAssignment={groupVariableAssignment}
              />
            </TherapyElementContainer>
          );
        }

        return null;
      })}
    </TherapyConfigForm>
  );
}

export default ParticipantsGroupsTherapyConfigContent;
