import { createRoot } from "react-dom/client";
import React, { Suspense } from "react";
import "./index.css";
import "./i18next";

import { App as AppAntdContext, ConfigProvider } from "antd";
import { ApolloProvider } from "@apollo/client";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import Keycloak from "keycloak-js";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { buildApolloClients } from "./services/graphql";
import {
  LoadingBackground,
  LoadingBackgroundPlain,
  LoadingContextProvider,
  primaryColor,
} from "./shared";

const keycloak = new Keycloak({
  realm: import.meta.env.VITE_REACT_APP_KEYCLOAK_REALM!,
  url: import.meta.env.VITE_REACT_APP_KEYCLOAK_URL!,
  clientId: import.meta.env.VITE_REACT_APP_KEYCLOAK_CLIENT_ID!,
});

const keycloakProviderInitConfig = {
  onLoad: "check-sso",
  silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
};

const { apolloClient } = buildApolloClients(keycloak);

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
  <Suspense fallback={<LoadingBackgroundPlain />}>
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={keycloakProviderInitConfig}
      autoRefreshToken={false}
      LoadingComponent={<LoadingBackground />}
    >
      <ApolloProvider client={apolloClient}>
        <LoadingContextProvider>
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: primaryColor,
                colorTextHeading: "#08979c",
                colorInfo: "#0084FF",
                colorSuccess: "#36B401",
                colorError: "#E10033",
                colorLink: primaryColor,
              },
              components: {
                Table: { colorTextHeading: "gray", colorIconHover: primaryColor },
                Modal: { colorTextHeading: "black" },
                Form: { colorTextHeading: "black" },
                Notification: {
                  colorTextHeading: "black",
                },
                Menu: {
                  itemMarginInline: 0,
                  lineHeight: 48,
                  colorItemBgSelected: "#08979C17",
                },
                Layout: { padding: 0 },
              },
            }}
          >
            <AppAntdContext>
              <App />
            </AppAntdContext>
          </ConfigProvider>
        </LoadingContextProvider>
      </ApolloProvider>
    </ReactKeycloakProvider>
  </Suspense>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
