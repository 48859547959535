import { Affix, Button, Form, App, notification, Space, Typography, Modal } from "antd";
import { Store } from "antd/lib/form/interface";
import ReactRouterPrompt from "react-router-prompt";
import React, { useState, useEffect, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import useTherapyDesign from "../../../../services/graphql/hooks/useTherapyDesign";
import { PageHeader, ErrorBoundary, useCachedStudy } from "../../../../shared";
import { filterForEditedTherapyValues } from "../../../../utils";
import { ChangedValuesTable, VariableType } from "./formItems";

const { Paragraph, Text } = Typography;

interface ITherapyConfigFormProps {
  title: string;
  description: string;
  editable: boolean;
  additionalHeaderContent?: ReactNode;
  // eslint-disable-next-line no-unused-vars
  onFinish?: (values: any) => void;
  initialValues?: Store | undefined;
  affixOffsetTop?: number;
  children?: React.ReactNode;
  forceValueRefresh?: boolean;
}

export function TherapyConfigForm({
  title,
  description,
  editable,
  affixOffsetTop,
  additionalHeaderContent = null,
  children,
  initialValues,
  onFinish = () => {},
  forceValueRefresh = false,
}: ITherapyConfigFormProps) {
  const [form] = Form.useForm();
  const { modal } = App.useApp();
  const [isInEditMode, setIsInEditMode] = useState(false);
  const { t } = useTranslation();
  const { study } = useCachedStudy();
  const { getTherapyVariable } = useTherapyDesign(study.id);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isInEditMode) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }
  });

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [forceValueRefresh]);

  const handleEditClick = () => {
    if (isInEditMode) {
      const thereAreErrors = form.getFieldsError().find((error) => error.errors.length > 0);
      if (thereAreErrors) {
        notification.error({
          message: "there are errors",
        });
      } else {
        setLoading(true);
        handleOnFormFinish(form.getFieldsValue());
      }
    } else {
      setIsInEditMode(true);
    }
  };

  const handleCancelClick = () => {
    setIsInEditMode(false);
    form.resetFields();
  };

  const handleOnFormFinish = (values: any) => {
    const filteredValues = filterForEditedTherapyValues({ editedValues: values, initialValues });

    setLoading(false);
    modal.confirm({
      title: t("study.therapyConfig.confirmChanges.title"),
      style: { minWidth: 600 },
      content:
        filteredValues.length > 0 ? (
          <div style={{ maxHeight: "60vH", overflowY: "auto" }}>
            <Paragraph>{t("study.therapyConfig.confirmChanges.description")}</Paragraph>
            <ChangedValuesTable
              changedValues={filteredValues.map((filteredValue) => {
                const variable = getTherapyVariable(filteredValue.key);
                return {
                  key: variable?.displayName || filteredValue.key,
                  newValue: filteredValue.currentValue.value,
                  oldValue: filteredValue.oldValue.value,
                  validatorProps: variable?.validatorProps,
                  type: variable?.type || VariableType.UNKNOWN,
                };
              })}
            />
          </div>
        ) : (
          <Text>{t("study.therapyConfig.confirmChanges.descriptionNoChanges")}</Text>
        ),
      okText: t("common.ok"),
      // @ts-ignore
      okButtonProps: { "data-id": "common.ok" },
      cancelText: t("common.cancel"),
      onOk: () => {
        if (filteredValues.length > 0) {
          onFinish(values);
        }
        setIsInEditMode(false);
      },
    });
  };

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      const newProps = {
        editing: isInEditMode,
      };
      return React.cloneElement(child, newProps);
    }
    return child;
  });

  return (
    <ErrorBoundary>
      <ReactRouterPrompt
        when={(args) =>
          isInEditMode && args.currentLocation.pathname !== args.nextLocation.pathname
        }
      >
        {({ isActive, onConfirm, onCancel }) => (
          <Modal
            cancelText={t("common.cancel")}
            closable={false}
            okText={t("common.ok")}
            okType="danger"
            onCancel={onCancel}
            onOk={onConfirm}
            title={t("study.therapyConfig.leavePageWhenEditingTitle")}
            open={isActive}
          >
            <p>{t("study.therapyConfig.leavePageWhenEditing")}</p>
          </Modal>
        )}
      </ReactRouterPrompt>
      <PageHeader
        style={{ width: "100%", padding: 0 }}
        title={title}
        description={description}
        extra={[
          <Affix key="editSave" offsetTop={affixOffsetTop} style={{ minWidth: 200 }}>
            <Space style={{ float: "right" }}>
              <Button
                key="cancelButton"
                style={{ display: isInEditMode ? "inline" : "none" }}
                onClick={handleCancelClick}
              >
                {t("common.cancel")}
              </Button>
              <Button
                data-id="editButton"
                disabled={!editable}
                key="editButton"
                loading={loading}
                onClick={handleEditClick}
                type="primary"
              >
                {isInEditMode ? t("common.save") : t("common.edit")}
              </Button>
            </Space>
          </Affix>,
        ]}
      >
        {additionalHeaderContent}
      </PageHeader>

      <Form form={form} initialValues={initialValues} scrollToFirstError>
        {childrenWithProps}
      </Form>
    </ErrorBoundary>
  );
}

export default TherapyConfigForm;
