/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
import { Checkbox, Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import React from "react";
import { useTranslation } from "react-i18next";
import { VariableType } from "./TherapyConfigFormItem";
import { ISelectItemValidatorProps } from "./SelectItem";

interface IChangedValue {
  key: string;
  oldValue: any;
  newValue: any;
  type: string;
  validatorProps?: ISelectItemValidatorProps;
}

interface IChangedValuesTable {
  changedValues: IChangedValue[];
}

const { Text } = Typography;

/**
 * Tabular overview showing the changes in the therapy configuration. Used in a
 * modal dialogue that the user must confirm before the therapy configuration is
 * saved.
 *
 */
export function ChangedValuesTable({ changedValues }: IChangedValuesTable) {
  const { t } = useTranslation();
  const columns: ColumnsType<IChangedValue> = [
    {
      title: t("study.therapyConfig.changedValuesTable.parameter"),
      dataIndex: "key",
      key: "paramater",
    },
    {
      title: t("study.therapyConfig.changedValuesTable.oldValue"),
      dataIndex: "oldValue",
      key: "oldValue",
      render: (value, record, index) => (
        <CellRender value={value} type={record.type} validatorProps={record.validatorProps} />
      ),
    },
    {
      title: t("study.therapyConfig.changedValuesTable.newValue"),
      dataIndex: "newValue",
      key: "newValue",
      render: (value, record, index) => (
        <CellRender
          value={value}
          type={record.type}
          validatorProps={record.validatorProps}
          highlight
        />
      ),
    },
  ];
  return <Table pagination={false} dataSource={changedValues} columns={columns} />;
}

interface ICellRenderProps {
  value: any;
  type: string;
  // eslint-disable-next-line react/require-default-props
  highlight?: boolean;
  validatorProps?: ISelectItemValidatorProps;
}

function CellRender({ value, type, highlight = false, validatorProps }: ICellRenderProps) {
  switch (type) {
    case VariableType.BOOLEAN: {
      return <Checkbox checked={value} disabled={!highlight} />;
    }
    case VariableType.TIME_RANGE: {
      return (
        <Text strong={highlight}>
          {value.start} - {value.end}
        </Text>
      );
    }
    case VariableType.TIME:
    case VariableType.TEXT: {
      return <Text strong={highlight}>{value}</Text>;
    }
    case VariableType.SINGLE_SELECT:
      return (
        <Text strong={highlight}>
          {validatorProps?.options?.find((option: any) => option.value === value)?.label || "?"}
        </Text>
      );
    case VariableType.MULTI_SELECT: {
      return (
        <ul>
          {value.map((item: any) => (
            <li key={item}>
              <Text strong={highlight}>
                {validatorProps?.options?.find((option: any) => option.value === item)?.label ||
                  "?"}
              </Text>
            </li>
          ))}
        </ul>
      );
    }
    default: {
      return <Text type="danger">Missing Representation for {type}</Text>;
    }
  }
}

export default ChangedValuesTable;
