import { Button, Space, Modal, Typography, Steps, Collapse } from "antd";
import React, { useState } from "react";
import {
  CheckCircleOutlined,
  CheckOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  ExclamationOutlined,
  MonitorOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { errorRedPrimary, primaryColor, successGreenPrimary } from "../..";
import logo from "../../assets/launcher_icon.png";
import { SendTestNotificationOutput, useSendTestNotification } from "../../../services";

const { Title, Paragraph, Text } = Typography;

// eslint-disable-next-line no-shadow, no-unused-vars
enum StepsEnum {
  // eslint-disable-next-line no-unused-vars
  INFO = 0,
  // eslint-disable-next-line no-unused-vars
  SEND = 1,
  // eslint-disable-next-line no-unused-vars
  RESULT = 2,
}

interface ITestResultProps {
  notificationResult?: SendTestNotificationOutput;
  participantId: string;
}

const getSteps = (t: TFunction) => [
  {
    key: StepsEnum.INFO,
    title: t("participant.sendTestNotification.info.title"),
    content: () => (
      <>
        <Paragraph>{t("participant.sendTestNotification.info.preContent")}</Paragraph>
        <Paragraph strong>{t("participant.sendTestNotification.info.preContentWarning")}</Paragraph>
      </>
    ),
    nextButtonText: t("common.understood"),
    buttonIcon: <CheckOutlined />,
    stepIcon: <ExclamationOutlined />,
  },
  {
    key: StepsEnum.SEND,
    title: t("participant.sendTestNotification.send.title"),
    content: () => (
      <>
        <Paragraph>{t("participant.sendTestNotification.send.content")}</Paragraph>
        <Notification
          message={
            <>
              Test notification from <i>[Your Name]</i>: <i>[Random Number]</i>
            </>
          }
        />
      </>
    ),
    nextButtonText: t("common.send"),
    stepIcon: <SendOutlined />,
    buttonIcon: <SendOutlined />,
  },
  {
    key: StepsEnum.RESULT,
    title: t("participant.sendTestNotification.success.title"),
    content: ({ participantId, notificationResult }: ITestResultProps) => (
      <TestResult notificationResult={notificationResult} participantId={participantId} />
    ),
    nextButtonText: t("common.close"),
    buttonIcon: <CloseOutlined />,
    stepIcon: <MonitorOutlined />,
  },
];

/**
 * Component to guide the user through the process of sending a test notification.
 * The component is expected to be used inside an antd Modal.
 */
export function SendTestNotification({ participantId }: { participantId: string }) {
  const [currentStep, setCurrentStep] = useState(StepsEnum.INFO);
  const { sendTestNotification, loading } = useSendTestNotification(participantId);
  const [notificationResult, setNotificationResult] = useState<
    SendTestNotificationOutput | undefined
  >(undefined);
  const { t } = useTranslation();
  const steps = getSteps(t);

  const handleNextStep = async () => {
    switch (currentStep) {
      case StepsEnum.INFO:
        setCurrentStep(StepsEnum.SEND);
        break;
      case StepsEnum.SEND:
        await sendTestNotification().then((data) => {
          setNotificationResult(data);
          setCurrentStep(StepsEnum.RESULT);
        });
        break;
      case StepsEnum.RESULT:
        Modal.destroyAll();
        break;
      default:
    }
  };

  return (
    <>
      <div
        style={{
          borderColor: `${primaryColor}60`,
          padding: 16,
          borderStyle: "none",
          borderWidth: 1,
          borderRadius: 8,
          backgroundColor: "#fafafa",
        }}
      >
        <Steps
          style={{ paddingLeft: 16, paddingRight: 16 }}
          current={currentStep}
          items={steps.map((step) => ({ icon: step.stepIcon }))}
        />

        <div
          style={{
            marginTop: 16,
          }}
        >
          <Title level={5} style={{ color: "black", marginTop: 0 }}>
            {steps[currentStep].title}
          </Title>
          <Paragraph>{steps[currentStep].content({ participantId, notificationResult })}</Paragraph>
        </div>
      </div>
      <Space style={{ float: "right", marginTop: 16 }}>
        {currentStep !== StepsEnum.RESULT && (
          <Button onClick={() => Modal.destroyAll()}>{t("common.cancel")}</Button>
        )}
        <Button
          icon={steps[currentStep].buttonIcon}
          type="primary"
          onClick={() => handleNextStep()}
          loading={loading}
        >
          {steps[currentStep].nextButtonText}
        </Button>
      </Space>
    </>
  );
}

/**
 * Component to display the result of the send notification test.
 */
export function TestResult({ notificationResult, participantId }: ITestResultProps) {
  const { t } = useTranslation();
  if (notificationResult?.success) {
    return (
      <div>
        <Space size={16} style={{ color: successGreenPrimary }}>
          <CheckCircleOutlined />
          <Text strong>{t("participant.sendTestNotification.testResult.success.title")}</Text>
        </Space>
        <Paragraph>
          {t("participant.sendTestNotification.testResult.success.description")}
        </Paragraph>

        <Notification
          message={notificationResult.sentMessage}
          style={{ marginTop: 16, marginBottom: 16 }}
        />
        <Collapse
          style={{ padding: 0 }}
          size="small"
          items={[
            {
              key: "1",
              label: t("participant.sendTestNotification.testResult.troubleshot.title"),
              children: (
                <div>
                  <Paragraph>
                    {t("participant.sendTestNotification.testResult.troubleshot.description")}
                  </Paragraph>
                  <ul>
                    <li>{t("participant.sendTestNotification.testResult.troubleshot.tip4")}</li>
                    <li>{t("participant.sendTestNotification.testResult.troubleshot.tip1")}</li>
                    <li>{t("participant.sendTestNotification.testResult.troubleshot.tip2")}</li>
                    <li>{t("participant.sendTestNotification.testResult.troubleshot.tip3")}</li>
                  </ul>
                </div>
              ),
            },
          ]}
        />
      </div>
    );
  }
  return (
    <div>
      <Space size={16} style={{ color: errorRedPrimary }}>
        <CloseCircleOutlined />
        <Text strong>{t("participant.sendTestNotification.testResult.error.title")}</Text>
      </Space>
      <Paragraph>{t("participant.sendTestNotification.testResult.error.description")}</Paragraph>
      <Paragraph strong style={{ textAlign: "center", fontSize: 16 }}>
        {participantId}
      </Paragraph>
    </div>
  );
}

/**
 * Component that imitates a TherapyDesigner smartphone notification.
 */
function Notification({
  message,
  style,
}: {
  message: string | React.JSX.Element;
  style?: React.CSSProperties;
}) {
  return (
    <div
      style={{
        padding: 4,
        width: "95%",
        paddingRight: 16,
        borderRadius: 12,
        borderColor: "#8c8c8c",
        borderStyle: "solid",
        borderWidth: 1,
        backgroundColor: "white",
        boxShadow: "0px 5px 5px 0px rgba(0,0,0,0.25)",
        ...style,
      }}
    >
      <Space size={12}>
        <div>
          <img src={logo} alt="TherapyDesigner" style={{ width: 32, height: 32 }} />
        </div>
        <div>
          <Text strong>TherapyDesigner</Text>
          <br />
          <Text>{message}</Text>
        </div>
      </Space>
    </div>
  );
}

export default SendTestNotification;
