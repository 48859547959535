import { useValidateParticipantConfig } from "../../../../services";

export interface IFieldData {
  variableId: string;
  value: any;
}

/**
 * Placeholder hook that generates some therapy design specific information
 * content that is displayed on the therapy configuration page depending on
 * the current configuration. This content is to be retrieved from the
 * executor via REST CALL.
 *
 * @param participantId
 * @returns
 */
export function useValidateParticipantTherapyConfig(participantId: string) {
  const { validate, validationResult } = useValidateParticipantConfig();

  const validateVariableValue = async ({
    values,
  }: {
    values: IFieldData[];
    variableId: string;
  }) => {
    const ans = await validate({ participantId, values });
    const validationResults = ans.data?.validateParticipantTherapyConfigAction.results;

    return validationResults || undefined;
  };

  return {
    validationInfoText: validationResult?.validationInfoItem,
    validateVariableValue,
  };
}

export default useValidateParticipantTherapyConfig;
