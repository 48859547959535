import React from "react";
import { Typography, TimePicker, Form } from "antd";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { IBaseFormItemProps, ITherapyConfigInputProps } from "./TherapyConfigFormItem";

const { Text } = Typography;
const { RangePicker } = TimePicker;

const format = "HH:mm";

export default function TimeRangeFormItem({
  editing,
  name,
  validatorProps,
  mandatory,
  executorValidationRule: rule,
}: IBaseFormItemProps) {
  const { t } = useTranslation();
  return (
    <Form.Item
      name={name}
      rules={[
        rule,
        () => ({
          validator(_, value) {
            const minTimeSpan = validatorProps?.minTimeSpan;
            if (minTimeSpan) {
              const start = dayjs(value.value.start, format);
              const end = dayjs(value.value.end, format);
              // const earliestStart=dayjs("7:00", format);
              // const latestStart=dayjs("10:00", format);
              // const earliestEnd=dayjs("18:00",format);
              // const latestEnd=dayjs("23:00", format)

              const minTimeSpanPassed = end.diff(start, "minute") >= minTimeSpan;
              // const earliestStartPassed=start.diff(earliestStart,"minute")>=0
              // const latestStartPassed=start.diff(latestStart,"minute")<=0
              // const earliestEndPssed=end.diff(earliestEnd,"minute")>=0
              // const latestEndPassed=end.diff(latestEnd,"minute")<=0

              if (minTimeSpanPassed) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error(
                  t("study.therapyConfig.FormItem.TimeRangeItem.validationError", { minTimeSpan }),
                ),
              );
            }
            return Promise.resolve();
          },
        }),
      ]}
    >
      <TimeRangeInput mandatory={mandatory} editing={editing} />
    </Form.Item>
  );
}

interface ITimeRangeInputProps extends ITherapyConfigInputProps {
  mandatory: boolean;
}

function TimeRangeInput({
  editing,
  mandatory,
  value: groupVariableConfigValue,
  onChange,
  onBlur,
}: ITimeRangeInputProps) {
  if (!editing) {
    return (
      <Text
        strong
      >{`${groupVariableConfigValue.value.start} - ${groupVariableConfigValue.value.end}`}</Text>
    );
  }

  return (
    <RangePicker
      style={{ width: "100%" }}
      format={format}
      allowClear={false}
      allowEmpty={[!mandatory, !mandatory]}
      value={[
        dayjs(groupVariableConfigValue.value.start, format),
        dayjs(groupVariableConfigValue.value.end, format),
      ]}
      onBlur={onBlur}
      onChange={(e) => {
        if (onChange)
          onChange({
            ...groupVariableConfigValue,
            value: {
              ...groupVariableConfigValue.value,
              start: e?.[0]?.format(format),
              end: e?.[1]?.format(format),
            },
          });
      }}
    />
  );
}
