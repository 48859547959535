import React, { useMemo } from "react";
import { ColumnProps } from "antd/lib/table";
import { Table } from "antd";
import { useTranslation } from "react-i18next";

import {
  GroupVariableAssignment,
  ParticipantsGroupFromQuery,
  ITherapyVariable,
} from "../../../../services";
import EditableCell from "./EditableCell";
import { primaryColor } from "../../../../shared";

export interface IRowItemType {
  key: string;
  displayName: string;
  description: string;
  variableType: string;
  mandatory: boolean;
  validatorProps: any;
  /** groupId : assignmentId */
  [x: string]: any;
}

export interface IGroupTableProps {
  editing?: boolean;
  therapyVariables: ITherapyVariable[];
  groups: ParticipantsGroupFromQuery[];
  variablesAssignment: GroupVariableAssignment[];
}

export function TherapyElementsGroupConfig({
  editing = false,
  therapyVariables,
  groups,
  variablesAssignment,
}: IGroupTableProps) {
  const { t } = useTranslation();
  const initData: IRowItemType[] = useMemo(
    () =>
      therapyVariables
        .filter((variable) => variable.configurationLevel.groupEdit)
        .sort((a, b) => b.order - a.order)
        .map((filteredVariable) => {
          const groupValuesArray = groups.flatMap((group) => {
            const variableAssignment = variablesAssignment.find(
              (assignment) =>
                assignment.variableId === filteredVariable.id &&
                assignment.participantsGroupId === group.id,
            );
            if (variableAssignment === undefined) {
              // ToDo: how should we handle a missing assignment??
              throw Error();
            } else {
              return {
                key: group.id,
                value: variableAssignment.id,
              };
            }
          });
          const groupValuesObject = Object.fromEntries(
            groupValuesArray.map((e) => [e.key, e.value]),
          );
          return {
            key: filteredVariable.id,
            description: filteredVariable.description,
            displayName: filteredVariable.displayName,
            variableType: filteredVariable.type,
            validatorProps: filteredVariable.validatorProps,
            mandatory: filteredVariable.mandatory,
            ...groupValuesObject,
          };
        }),

    [variablesAssignment, therapyVariables, groups],
  );

  // ToDo: how to adjust width of columns
  const groupCoulumns: ColumnProps<IRowItemType>[] = groups.map((group) => ({
    key: group.id,
    width: `${20}%`,
    title: group.name,
    // record = element frm initData
    onCell: (record: IRowItemType) => ({
      dataIndex: record[group.id],
      editableCell: true,
      variableType: record.variableType,
      mandatory: record.mandatory,
      validatorProps: record.validatorProps,
      editing,
      title: group.name,
    }),
  }));

  const columns = [
    {
      title: t("study.therapyConfig.variableName"),
      dataIndex: "displayName",
      width: "10%",
    },
    {
      title: t("study.therapyConfig.variableDescription"),
      dataIndex: "description",
      width: "20%",
    },
    {
      title: t("study.therapyConfig.groupValue"),
      dataIndex: "values",

      children: groupCoulumns,
    },
  ];

  return (
    <Table
      style={{
        margin: "auto",
        borderStyle: editing ? "solid" : "none",
        borderWidth: 1,
        borderRadius: 4,
        borderColor: primaryColor,
        boxShadow: editing ? `0px 0px 20px 8px ${primaryColor}` : "",
      }}
      components={{
        body: {
          cell: EditableCell,
        },
      }}
      pagination={false}
      bordered
      dataSource={initData}
      columns={columns}
    />
  );
}
