import i18n from "i18next";
import i18nextBrowserLanguagedetector from "i18next-browser-languagedetector";
import i18nextHttpBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

// i18next-http-backend loads translations from your server https://github.com/i18next/i18next-http-backend
// detect user language learn more: https://github.com/i18next/i18next-browser-languageDetector
// pass the i18next instance to react-i18next
// init i18next for all options read: https://www.i18next.com/overview/configuration-options

declare module "i18next" {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

i18n
  .use(i18nextHttpBackend)
  .use(i18nextBrowserLanguagedetector)
  .use(initReactI18next)
  .init({
    backend: {
      requestOptions: {
        cache: "reload",
      },
    },
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed as react escapes by default
    },
  });

export default i18n;
