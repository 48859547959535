import React from "react";
import { Input, Typography, Form } from "antd";
import { useTranslation } from "react-i18next";
import { IBaseFormItemProps, ITherapyConfigInputProps, VariableType } from ".";

const { Text } = Typography;

export interface IVariableConfigInputProps extends ITherapyConfigInputProps {
  variableType: string;
  name?: string;
}

export interface IStandardFormItemProps extends IBaseFormItemProps {
  variableType: string;
}

export default function StandardFormItem({
  editing,
  name,
  variableType,
  mandatory,
  executorValidationRule,
}: IStandardFormItemProps) {
  const { t } = useTranslation();

  return (
    <Form.Item
      name={name}
      validateTrigger="onBlur"
      rules={[
        () => ({
          validator(_, value) {
            if (mandatory && !value.value.trim()) {
              return Promise.reject(new Error(t("study.therapyConfig.FormItem.missingValue")));
            }
            return Promise.resolve();
          },
        }),
        executorValidationRule,
      ]}
    >
      <StandardInput name={name} editing={editing} variableType={variableType} />
    </Form.Item>
  );
}

export const StandardFormItemTypes = ["Text", "Decimal", "Integer", "Date", "DateTime", "Time"];

function StandardInput({
  name,
  variableType,
  editing,
  value: variableValue,
  onChange,
  onBlur,
}: IVariableConfigInputProps) {
  const getInputFieldType = (inputType: string) => {
    switch (inputType) {
      case VariableType.TEXT:
        return "text";
      case VariableType.DECIMAL:
        return "decimal";
      case VariableType.INT:
        return "number";
      case VariableType.DATE:
        return "date";
      case VariableType.DATETIME:
        return "datetime-local";
      case VariableType.TIME:
        return "time";
      default:
        return "text";
    }
  };

  if (!editing) {
    return <Text strong>{variableValue.value}</Text>;
  }

  return (
    <Input
      data-id={name}
      onChange={(e) => {
        if (onChange) onChange({ ...variableValue, value: e.target.value });
      }}
      onBlur={onBlur}
      value={variableValue.value}
      type={getInputFieldType(variableType)}
    />
  );
}
