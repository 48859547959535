import React from "react";
import { useTranslation } from "react-i18next";
import { backGroundColorGradient, loginTitleStyle } from "../layout";

interface ILoadingBackgroundProps {
  // eslint-disable-next-line react/require-default-props
  loadingText?: string;
}

export function LoadingBackground({ loadingText }: ILoadingBackgroundProps) {
  const { t } = useTranslation();
  return (
    <div style={{ height: "100vH", width: "100vW", backgroundImage: backGroundColorGradient }}>
      <div style={{ ...loginTitleStyle }}>TherapyDesigner</div>
      <div
        style={{
          ...loginTitleStyle,
          letterSpacing: 1,
          marginTop: 0,
          paddingTop: 40,
          fontSize: "18px",
        }}
      >
        {loadingText || t("common.loading")}
      </div>
    </div>
  );
}

export function LoadingBackgroundPlain() {
  return (
    <div style={{ height: "100vH", width: "100vW", backgroundImage: backGroundColorGradient }}>
      <div style={{ ...loginTitleStyle }}>TherapyDesigner</div>
      <div
        style={{
          ...loginTitleStyle,
          letterSpacing: 1,
          marginTop: 0,
          paddingTop: 40,
          fontSize: "18px",
        }}
      >
        ...
      </div>
    </div>
  );
}

export default LoadingBackground;
